<template>
  <div class="info-item">
      <div class="body">
        <div
          class="column products"
          v-for="item in productadvList"
          :key="item.id"
        >
          <ul>
            <li>
              <a class="image" href="javascript:;">
                <img :src="item.image" alt="" />
              </a>
              <div class="info">
                <p class="name ellipsis-2">
                  {{ item.name }}
                </p>
                <p class="attr ellipsis">
                  {{ item.summary }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
    "result":  [
            {
                "id": "1586920235652665346",
                "name": "竞争优势",
                "summary": "让客户认同了产品的优势才能激发其购买欲望",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys1.png"
            },
            {
                "id": "1586920235652665346",
                "name": "竞争劣势",
                "summary": "培养其品牌忠诚度",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys2.png"
            },
            {
                "id": "1586920235652665346",
                "name": "机会和威胁",
                "summary": "产品优势主要体现在品牌效应、价格优势、特殊优势",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys3.png"
            },
            {
                "id": "1586920235652665346",
                "name": "不可替代",
                "summary": "把产品的优势毫无保留地展示给客户",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys4.png"
            },
            {
                "id": "1586920235652665346",
                "name": "价格优势",
                "summary": "客户越来越重视产品的品牌知名度",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys5.png"
            },
            {
                "id": "1586920235652665346",
                "name": "特殊优势",
                "summary": "将其作为重要的卖点介绍给客户",
                "createTime": "2022-10-31 11:17:22",
                "image": "http://demo.hyxdsoft.com/img/web10001/ys6.png"
            }
        ]
}
    return { productadvList: result, target }
  }
}
</script>

<style scoped lang="less">
.info-item {
  background-color: #fff;
  padding: 20px;
}

// 主体样式
.login-section {
  height: 688px;
  position: relative;
  .wrapper {
    width: 680px;
    position: absolute;
    left: 50%;
    margin-left: -28%;
    top: 54px;
    nav {
      font-size: 24px;
      height: 55px;
      padding: 20px 40px;
      text-align: center;
      align-items: center;
      color: #fff;
    }
  }
}

// 账号容器
.account-box {
  .toggle {
    padding: 10px 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .form {
    padding: 20px 10px;
    &-item {
      margin-bottom: 28px;
    }
    .video-play {
      border: 10px solid #e4e4e4;
    }
    .agree {
      font-size: 16px;
      padding: 10px 20px;
      align-items: center;
      color: #fff;
    }
  }
}

.info-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;

    flex-wrap: wrap;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.products {
        flex: 1;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 100px;
              height: 100px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                  font-weight: bold;
                  padding: 10px;
                  font-size: 18px;
                }
                &.attr {
                  color: #999;
                  font-size: 16px;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .enterprise {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.state {
        width: 120px;
        .green {
          color: #999;
        }
      }
      &.amount {
        width: 200px;
        .red {
          color:#999;
        }
      }
      &.action {
        width: 140px;
        a {
          display: block;
          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
